import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { theme } from '@icepanel/app-theme'
import Vue from 'vue'
import Vuetify, {
  VApp,
  VAppBar,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBtn,
  VBtnToggle,
  VCard,
  VCheckbox,
  VChip,
  VCol,
  VCombobox,
  VContainer,
  VDataTable,
  VDialog,
  VDivider,
  VExpandTransition,
  VExpandXTransition,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFadeTransition,
  VFileInput,
  VForm,
  VHover,
  VIcon,
  VImg,
  VList,
  VListItem,
  VListItemAction,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
  VOverlay,
  VProgressCircular,
  VProgressLinear,
  VRow,
  VSelect,
  VSheet,
  VSkeletonLoader,
  VSlider,
  VSnackbar,
  VSwitch,
  VTab,
  VTabs,
  VTextarea,
  VTextField,
  VTooltip,
  VTreeview,
  VVirtualScroll
} from 'vuetify/lib'

import icons from '@/helpers/icons'

import customIcons from '../helpers/custom-icons'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

const iconDefinitions = [...customIcons, ...icons]
const iconValues: any = {}
iconDefinitions.forEach(definition => {
  library.add(definition as IconDefinition)

  iconValues[`${definition.prefix}-${definition.iconName}`] = {
    component: FontAwesomeIcon,
    props: {
      icon: [definition.prefix, definition.iconName]
    }
  }
})

Vue.use(Vuetify, {
  components: {
    VApp,
    VAppBar,
    VAutocomplete,
    VAvatar,
    VBadge,
    VBtn,
    VBtnToggle,
    VCard,
    VCheckbox,
    VChip,
    VCol,
    VCombobox,
    VContainer,
    VDataTable,
    VDialog,
    VDivider,
    VExpandTransition,
    VExpandXTransition,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VFadeTransition,
    VFileInput,
    VForm,
    VIcon,
    VImg,
    VList,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemContent,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VMenu,
    VOverlay,
    VProgressCircular,
    VProgressLinear,
    VRow,
    VSelect,
    VSheet,
    VSkeletonLoader,
    VSlider,
    VSnackbar,
    VSwitch,
    VTab,
    VTabs,
    VTextField,
    VTextarea,
    VTooltip,
    VTreeview,
    VVirtualScroll
  },
  directives: {
    VHover
  }
})

export default new Vuetify({
  icons: {
    iconfont: 'faSvg',
    values: iconValues
  },
  theme
})
